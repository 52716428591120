.login{
  position: absolute;
  width: 370px;
  height: 400px;
  top: 20%;
  left: 50%;
  margin-left: -185px;
}
.login .logo{
  position: relative;
  left: 50%;
  margin-left: -75px;
  margin-bottom: 30px;
}
.login .login-form-input {
  width: 100%;
  height: 40px;
}
.login .login-form-input .ant-input{
  font-size: 16px;
}
.login .ant-form-item-label .ant-form-item-required{
  font-size: 16px!important;
  color: #1890FF!important;
}
.login .ant-form-item-label .ant-form-item-required:before,
.login .ant-form-item-label .ant-form-item-required:after{
  display: none;
}
.login .ant-form-item{
  margin-bottom: 15px;
}
.login .login-form-submit{
  margin-top: 10px;
}
.login .login-form-input.ant-input-affix-wrapper{
  position: relative;
}
.login .pd-show-hide{
  width: 50px;
  height: 39px;
  position: absolute;
  right: 0;
  top: -12px;
}
.login .pd-show-hide img{
  position: relative;
  left: 15px;
}

.home .left{
  position: absolute;
  width: 256px;
  height: 100vh;
  background-color: rgba(0, 21, 41, 1);
  z-index: 999;
}
.home .left .logo{
  width: 256px;
  height: 65px;
  line-height: 65px;
  background-color: rgba(0, 40, 77, 1);
  text-align: center;
}
.home .left .logo img{
  width: 106px;
}
.home .title{
  height: 120px;
  line-height: 100px;
  text-align: center;
  font-size: 20px;
  color: #56ACFF;
}
.home .content{
  position: absolute;
  width: 100vw;
  height: 100vh;
  padding-left: 256px;
  background-color: #F0F2F5;
  overflow: scroll;
}
.home .content .user-bar{
  height: 65px;
  width: 100%;
  border-bottom: 1px solid rgba(233, 233, 233, 1);
  background-color: #FFF;
}
.home .content .user-bar .avatar{
  height: 65px;
  line-height: 65px;
  width: 130px;
  float: right;
  text-align: center;
  transition: 0.5s;
  cursor: pointer;
}
.home .content .user-bar .avatar:hover{
  background: rgba(0, 0, 0, 0.025);
}
.home .content .user-bar .avatar img{
  width: 30px;
  border-radius: 15px;
  margin-right: 10px;
}
.home .content .user-bar .avatar span{
  font-size: 15px;
}

.details {
  margin: 20px;
  padding: 20px;
  background-color: #FFF;
}
.details .search{
  font-size: 14px;
  color: #000;
}
.details .search .item{
  margin-right: 20px;
}
.details .search .query .ant-btn:nth-child(1){
  margin-right: 10px;
}
.details .operation{
  margin-top: 20px;
}
.details .list{
  margin-top: 20px;
}
.details .list .ant-table-thead > tr > th, 
.details .list .ant-table-tbody > tr > td{
  padding: 10px 0;
}

.add-modal .left{
  display: inline-block;
  width: 300px;
}
.add-modal .right{
  display: inline-block;
  float: right;
  width: 300px;
}